import React from 'react'
import {graphql} from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import Header from "@solid-ui-blocks/Header/Block01/Block01";
import {Container} from "theme-ui";
import Footer from "@solid-ui-blocks/Footer/Block01/Block01";
import Features from "@solid-ui-blocks/Features/Block06/Block06";
import styles from "../portfolio/_styles";
import Divider from "@solid-ui-components/Divider";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block01/Block01";
import FeatureTwo from "@solid-ui-blocks/Features/Block02/Block02";
import Contact from '@solid-ui-blocks/CallToAction/Block02'

const Portfolio = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Portfolio'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <Header content={content['header']}/>
            <Container variant='full' sx={styles.heroContainer}>
                <Features content={content['features']}/>
            </Container>
            <Divider space='5'/>
            <FeatureOne content={content['portfolio-one']}/>
            <Divider space='3'/>
            <Contact content={content['cta']}/>
            <Divider space='5'/>
            <FeatureTwo content={content['portfolio-two']} reverse/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}


export const query = graphql`
    query innerpagePortfolio {
        allBlockContent(
            filter: { page: { in: ["site/portfolio", "shared"] } }
        ) {
            nodes {
                ...BlockContent
            }
        }
    }
`

export default Portfolio
